<template>
  <Layout>
    <div class="app-ad-pop-edit">
      <a-page-header title="弹窗广告" sub-title="自动弹窗引导用户进入活动" />

      <div class="adv-form">
        <a-form-model :model="form" ref="form" v-bind="horizonLayout">
          <a-form-model-item
            required
            label="广告名称"
            prop="name"
            :rules="[
              {
                required: true,
                message: '广告名称必填'
              }
            ]"
          >
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item
            required
            label="开始时间"
            prop="startTime"
            :rules="{
              required: true,
              message: '开始时间必填'
            }"
          >
            <a-date-picker
              v-model="form.startTime"
              valueFormat="YYYY-MM-DD HH:mm"
              format="YYYY-MM-DD HH:mm"
              :disabled-date="disabledStartDate"
              :show-time="{ format: 'h:mm' }"
            />
          </a-form-model-item>

          <a-form-model-item
            required
            label="结束时间"
            prop="endTime"
            :rules="{
              required: true,
              message: '结束时间必填'
            }"
          >
            <a-date-picker
              v-model="form.endTime"
              valueFormat="YYYY-MM-DD HH:mm"
              format="YYYY-MM-DD HH:mm"
              :disabled-date="disabledEndDate"
              :show-time="{ format: 'h:mm' }"
            />
          </a-form-model-item>

          <a-form-item
            label="广告图片"
            required
            :rules="[
              {
                required: true,
                message: '广告图片必填'
              }
            ]"
          >
            <a-upload
              :data="{ index: 0 }"
              listType="picture-card"
              :action="UPLOAD_URL"
              :headers="{ t: getToken }"
              withCredentials
              accept="image/*"
              :showUploadList="false"
              @change="handleUpload"
              :before-upload="beforeUpload"
            >
              <img v-if="form.img" :src="form.img" style="width:80px" />
              <div v-else>
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  上传
                </div>
              </div>
            </a-upload>
          </a-form-item>

          <a-form-model-item
            label="跳转"
            required
            :rules="[
              {
                required: true,
                message: '跳转必填'
              }
            ]"
          >
            <a-select v-model="form.target" placeholder="请选择">
              <a-select-option v-for="t in targetList2" :key="t.id" :value="t.id"
                >{{ t.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <!-- 选择器 -->
          <a-form-model-item label="地址">
            <!-- 商品 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="form.target == 1"
              v-model="form.productId"
              placeholder="请选择"
            >
              <a-select-option
                v-for="p in productList"
                :key="p.id"
                :value="p.id"
                >{{ p.title }}
              </a-select-option>
            </a-select>

            <!-- 带货商品 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="form.target == 6"
              v-model="form.productId"
              placeholder="请选择"
            >
              <a-select-option
                v-for="p in productList2"
                :key="p.id"
                :value="p.id"
                >{{ p.title }}
              </a-select-option>
            </a-select>

            <!-- 优惠券 -->
            <a-select
              v-if="form.target == 2"
              v-model="form.couponId"
              placeholder="请选择"
              notFoundContent="请在小商店后台创建券"
            >
              <a-select-option v-for="c in couponList" :key="c.id" :value="c.id"
                >{{ c.name }}
              </a-select-option>
            </a-select>

            <!-- 分类 -->
            <a-tree-select
              v-if="form.target == 3"
              v-model="form.categoryId"
              style="width: 100%"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="categoryTree"
              placeholder="请选择"
              notFoundContent="请在小商店后台创建分类"
              tree-default-expand-all
            >
            </a-tree-select>

            <!-- 页面 -->
            <a-select
              v-if="form.target == 4"
              v-model="form.pageId"
              placeholder="请选择"
            >
              <a-select-option v-for="c in pageList" :key="c.id" :value="c.id"
                >{{ c.name }}
              </a-select-option>
            </a-select>

            <!-- 拼团 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="form.target == 7"
              v-model="form.pinId"
              placeholder="请选择"
            >
              <a-select-option v-for="c in pinList" :key="c.id" :value="c.id"
                >{{ c.title }}
              </a-select-option>
            </a-select>

            <!-- 砍价 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="form.target == 8"
              v-model="form.kanId"
              placeholder="请选择"
            >
              <a-select-option v-for="c in kanList" :key="c.id" :value="c.id"
                >{{ c.title }}
              </a-select-option>
            </a-select>

            <!-- 分享有礼 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="form.target == 9"
              v-model="form.shareId"
              placeholder="请选择"
            >
              <a-select-option v-for="c in shareList" :key="c.id" :value="c.id"
                >{{ c.title }}
              </a-select-option>
            </a-select>

            <!-- 抽奖 -->
            <a-select
              show-search
              :filter-option="filterOption"
              v-if="form.target == 14"
              v-model="form.prizeId"
              placeholder="请选择"
            >
              <a-select-option v-for="c in prizeList" :key="c.id" :value="c.id"
                >{{ c.title }}
              </a-select-option>
            </a-select>

            <!-- 招募推荐官 -->
            <a-input
              v-if="form.target == 11"
              v-model="form.planId"
              :max-length="10"
              type="number"
              placeholder="请复制招募计划 planId"
            />

            <!-- 小程序 -->
            <a-input
              v-if="form.target == 15"
              v-model="form.minapp"
              :max-length="255"
              placeholder="appid#query 或 #小程序链接"
            />

            <!-- 网页 -->
            <a-input
              v-if="form.target == 12"
              v-model="form.url"
              :max-length="200"
              placeholder="请输入网页地址"
            />

            <!-- 拨打电话 -->
            <a-input
              v-if="form.target == 20"
              v-model="form.tel"
              :max-length="100"
              placeholder="请输入电话号码"
            />
            <!-- 复制文本 -->
            <a-input
              v-if="form.target == 21"
              v-model="form.text"
              :max-length="100"
              placeholder="请输入文本"
            />
          </a-form-model-item>

          <a-form-model-item label="出现频率">
            <a-radio-group
              @change="changeFreq"
              name="radioGroup"
              :default-value="1"
              v-model="freq"
            >
              <a-radio :value="1">只出现1次</a-radio>
              <a-radio :value="2">指定出现频率</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            v-if="freq === 2"
            required
            label="重复出现"
            prop="day"
            :rules="[
              {
                required: true,
                message: '天数必填'
              }
            ]"
          >
            <div class="day-input-wrap">
              每
              <a-input v-model="form.day" suffix="天" />
              出现一次
            </div>
          </a-form-model-item>

          <a-form-model-item :wrapper-col="{ span: 18, offset: 6 }">
            <a-button type="primary" html-type="submit" @click="submitForm">
              保存
            </a-button>
            <a-button @click="cancel" style="margin-left: 10px;">
              取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const moment = require("moment");

const AdPopSvc = require("@/service/ad-pop");

import loadDataMixin from "@/mixins/loadData";

import textMixin from "@/mixins/text";
import productMixin from "@/mixins/product";
import couponMixin from "@/mixins/coupon";
import categoryMixin from "@/mixins/category";
import pageMixin from "@/mixins/page";

import uploadMixin from "@/mixins/upload";
import targetMixin from "@/mixins/target";

export default {
  name: "AdPopEdit",

  data() {
    return {
      mode: "add", // 模式：add 批量新增，edit 单个编辑
      freq: 1,

      form: {
        name: "",
        startTime: null,
        endTime: null,

        img: null,

        target: 1,
        productId: null,
        categoryId: null,
        couponId: null,
        pageId: null,
        shareId: null,
        planId: null,
        minapp: null,

        day: 0
      }
    };
  },

  mixins: [
    loadDataMixin,

    textMixin,
    productMixin,
    couponMixin,
    categoryMixin,
    pageMixin,
    targetMixin,
    uploadMixin
  ],
  
  computed: {
    targetList2() {
      // 弹窗
      return this.targetList.filter(x => ![22].includes(x.id))
    },
  },

  methods: {
    // 活动日期
    disabledStartDate(startValue) {
      const endValue = this.form.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > moment(endValue).valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.form.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return moment(startValue).valueOf() >= endValue.valueOf();
    },

    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { url } = data;
        this.form.img = url;
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    },

    changeFreq(e) {
      const freq = e.target.value;
      this.form.day = freq === 1 ? 0 : 1;
    },

    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let { img } = this.form;
          if (!img) {
            this.$message.info("请上传广告图片");
            return;
          }

          try {
            let val = {
              ...this.form
            };

            if (this.mode === "edit") {
              let { id } = this.form;
              val.id = id;
            }
            await AdPopSvc.createOrUpdate(val);

            const msg = this.mode === "add" ? "创建成功" : "保存成功";
            this.$message.info(msg);
            this.$router.replace({ name: "AdPopList" });
          } catch (err) {
            console.error(err);
            this.$message.error("创建广告失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    cancel() {
      this.$router.replace({ name: "AdPopList" });
    }
  },

  async mounted() {
    let { id } = this.$route.query || {};
    this.mode = id ? "edit" : "add";

    if (id) {
      try {
        id = parseInt(id);
        let res = await AdPopSvc.detail(id);

        this.freq = res.day > 0 ? 2 : 1;
        this.form = {
          ...res,

          id
        };
      } catch (err) {
        console.error(err);
        this.$message.error("加载广告失败，错误：" + err.message);
      }
    }
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-ad-pop-edit {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .adv-form {
    padding: 14px;
    margin-bottom: 10px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;

    .ant-form {
      max-width: 600px;
    }

    .day-input-wrap {
      display: flex;
      align-items: center;

      .ant-input-affix-wrapper {
        width: 100px;
        margin: 0 5px;
      }
    }
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    padding: 10px;
    text-align: center;
  }
}
</style>
