import request from "./request";

/**
 * 弹窗广告
 */

/**
 * 查询弹窗
 */
export const list = () => request.get("/pop/");

/**
 * 查询弹窗详情
 */
export const detail = id => request.get("/pop/detail", { params: { id } });

/**
 * 创建/编辑
 */
export const createOrUpdate = data => request.post("/pop/", data);

/**
 * 切换状态
 */
export const updateStatus = id => request.post("/pop/status", { id });

/**
 * 删除
 */
export const del = id => request.post("/pop/del", { id });
